<template>
  <div class="thank-you-form">
    <img src="../assets/frosty.png" alt="" class="frosty">
    <!-- Updated form-wrapper with dynamic class binding -->
    <div class="form-wrapper" :class="{ 'bg-black': loading || response }">
      <form @submit.prevent="submitQuery" v-show="!loading && !response">
        <div class="form-row">
          <label for="name">Your Name</label>
          <input id="name" v-model="name" placeholder="Channing Merchandise">
        </div>
        <div class="form-row">
          <label for="gift">What kind of gift did you get?</label>
          <input id="gift" v-model="gift" placeholder="Fingerless gloves">
        </div>
        <div class="form-row">
          <label for="giftGiver">Who gave it to you?</label>
          <input id="giftGiver" v-model="giftGiver" placeholder="My wife's personal trainer">
        </div>
        <div class="form-row">
          <label for="whyGood">Why is it a good gift?</label>
          <input id="whyGood" v-model="whyIsItGood" placeholder="I don't mind leaving fingerprints behind">
        </div>
        <div class="form-row">
          <label for="tone">What kind of tone would you like?</label>
          <input id="tone" v-model="tone" placeholder="Sincere? Sarcastic?">
        </div>
        <button type="submit">Submit</button>
        <!-- Error Messages -->
        <div class="errors" v-if="formErrors.length">
          <ul>
            <li v-for="error in formErrors" :key="error">{{ error }}</li>
          </ul>
        </div>
      </form>
          <!-- Loading Indicator -->
      <img src="../assets/o-mark.gif" alt="" v-if="loading" class="isLoading">
      <span class="loadingText" v-if="loading">Sit tight. This part takes a few seconds.</span>

      <!-- Response -->
      <p v-if="response" class="response">{{ response }}</p>

      <!-- Reset Button -->
      <button class="resetButton" v-if="response" @click="resetForm">Try Another!</button>
    </div>

    <img src="../assets/robot.png" alt="" class="robot">
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      name: '',
      gift: '',
      giftGiver: '',
      whyIsItGood: '',
      tone: '',
      response: null,
      loading: false,
      formErrors: []
    };
  },
  methods: {
    validateForm() {
      this.formErrors = [];
      if (!this.name.trim()) this.formErrors.push('Name field is required.');
      if (!this.gift.trim()) this.formErrors.push('Gift field is required.');
      if (!this.giftGiver.trim()) this.formErrors.push('Gift Giver field is required.');
      if (!this.whyIsItGood.trim()) this.formErrors.push('Why is it Good field is required.');
      if (!this.tone.trim()) this.formErrors.push('Tone field is required.');

      return this.formErrors.length === 0;
    },
    async submitQuery() {
      if (!this.validateForm()) {
        return;
      }
      
      this.loading = true;
      const formattedQuery = `My Name: ${this.name}. Gift: ${this.gift}. Gift Giver: ${this.giftGiver}. Why is it Good: ${this.whyIsItGood}. Tone: ${this.tone}.`;
      try {
        const res = await axios.post('/api/query', { query: formattedQuery });
        this.response = res.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.name = '';
      this.gift = '';
      this.giftGiver = '';
      this.whyIsItGood = '';
      this.tone = '';
      this.response = null;
      this.loading = false;
      this.formErrors = [];
    }
  }
};
</script>


<style>
  @keyframes bob-up-down {
    0%, 80%, 100% {
      transform: translateY(0);
    }
    
    20%, 50%, 55% {
      transform: translateY(-10px);
    }
  }

  .thank-you-form{
    position: relative;
    max-width: 375px;
    margin: 0 auto;
  }
  .thank-you-form .form-wrapper{
    background-color: #ec008c;
    border: 2px solid #fff;
    border-radius: 5px;
    padding: 20px 0;
    margin: 155px auto 50px;
    position: relative;
    z-index: 1001;
    width: 80%;
    min-height: 200px;
  }

  .thank-you-form .form-wrapper.bg-black{
    background-color: #000;
  }

  .thank-you-form form .form-row{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 0 25px;
  }

  .thank-you-form form label{
    display: block;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 17px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: left;
  }

  .thank-you-form form input{
    border-color: #f737a8;
    display: block;
    font-size: 13px;
    font-weight: 400;
    padding: 10px;
    border-radius: 7px;
  }

  .thank-you-form form button{
    background-color: #fff;
    border-color: #ec008c;
    color: #ec008c;
    border-radius: 5px;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 18px;
    font-weight: 900;
    padding: 8px 50px 10px;
    margin: 10px 0 0;
  }

  .thank-you-form form button:hover{
    background-color: #2ba7e1;
    border-color: #2ba7e1;
    color: #fff;
    cursor: pointer;
  }

  .errors{
    color: #fff;
    font-weight: 900;
    font-size: 12px;
    font-style: italic;
    line-height: 18px;
    margin-top: 10px;
  }

  .errors ul{
    list-style: none;
    padding: 0;
  }

  /* Apply animation to frosty with initial rotation */
  .thank-you-form .frosty {
    position: absolute;
    left: 33px;
    max-width: 100px;
    top: -87px;
    z-index: 1;
    animation: bob-up-down 1.5s ease-in-out infinite;
  }

  /* Apply animation to robot with initial rotation and a phase shift */
  .thank-you-form .robot {
    position: absolute;
    right: 20px;
    max-width: 100px;
    top: -80px;
    z-index: 1;
    animation: bob-up-down 1.5s ease-in-out infinite .75s;
  }
  .isLoading{
    display: block;
    width: 50px;
    margin: 50px auto 0;
  }

  .loadingText{
    font-family:'Barlow Condensed',sans-serif;
    display:inline-block;
    font-size:12px;
    margin-top:10px;
  }
  
  .response{
    padding: 0px 20px;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 18px;
    font-weight: 500;
  }

  .resetButton{
    background-color: #2ba7e1;
    border-color: #2ba7e1;
    color: #fff;
    border-radius: 5px;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 16px;
    font-weight: 900;
    padding: 8px 50px 10px;
    margin: 20px 0 0;
  }

  .resetButton:hover{
    background-color: #000;
    border-color: #2ba7e1;
    color: #fff;
    cursor: pointer;
  }

  @media screen and (min-width: 480px) {
    .thank-you-form{
      max-width: 460px;
    }

    .thank-you-form .form-wrapper{
      max-width: 550px;
      margin: 125px auto 50px;
      padding: 30px 0;
    }

    .thank-you-form form input{
      padding: 12px;
    }
  }

  @media screen and (min-width: 768px) {
    .thank-you-form {
      max-width: 600px;
    }
    .thank-you-form .form-wrapper{
      margin: 130px auto 50px;
    }

    .thank-you-form form .form-row{

    }

    .thank-you-form form label{
      
    }

    .thank-you-form form input{
    
    }

    .thank-you-form form button{
      margin-top: 20px;
      padding: 10px 75px 12px;
    
    }


    .thank-you-form .frosty{
      left: 55px;
      max-width: 150px;
      top: -130px;
      z-index: 1;
    }

    .thank-you-form .robot{
      right: 35px;
      max-width: 160px;
      top: -130px;
      z-index: 1;
    }
  }

  @media screen and (min-width: 992px) {
    .thank-you-form form{
   
    }

    .thank-you-form form .form-row{

    }

    .thank-you-form form label{
      
    }

    .thank-you-form form input{
    
    }

    .thank-you-form form button{
    
    }

    .thank-you-form form button:hover{
      
    }
  }

  @media screen and (min-width: 1200px) {
    .thank-you-form form{
   
    }

    .thank-you-form form .form-row{

    }

    .thank-you-form form label{
      
    }

    .thank-you-form form input{
    
    }

    .thank-you-form form button{
    
    }

    .thank-you-form form button:hover{
      
    }
  }

  @media screen and (min-width: 1400px) {
    .thank-you-form form{
   
    }

    .thank-you-form form .form-row{

    }

    .thank-you-form form label{
      
    }

    .thank-you-form form input{
    
    }

    .thank-you-form form button{
    
    }

    .thank-you-form form button:hover{
      
    }
  }

</style>