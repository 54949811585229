<template>
  <div class="container">
    <div class="branding">
      <a id="header-logo" href="https://morrison.agency"><img src="./assets/morrison-logo-white.png" alt="Morrison" class="morrison-logo"></a>
      <h1>H<span><img src="./assets/wreath.png" alt="O"></span>LIDAY <br> GRATITUDE SIMULATOR</h1>
      <p>Feeling thankful(?) for that wool seat belt warmer or gently-used, tanning salon-scented candle? Fear not, the Morrison Holiday Gratitude Simulator is here to fake it till you make it. Just enter the gift details, kick back, and let it whip up some convincingly enthusiastic thank-you notes. Who knows, you might even crack a real smile in the process!</p>
    </div>
    <div class="light-row"></div>
    <CardConfig />
    <div class="footer">
      <p>Like what you see? <a id="footer-link" href="https://morrison.agency">Learn more</a> about all the other things Morrison can do for you.</p>
      <a id="footer-logo" href="https://morrison.agency"><img src="./assets/morrison-logo-white.png" alt="Morrison" class="morrison-logo"></a>
    </div>
    <div class="tree-row"></div>
  </div>
</template>

<script>
import CardConfig from './components/CardConfig.vue'

export default {
  name: 'App',
  components: {
    CardConfig
  }
}
</script>

<style>
html{
  background-color: #000;
  text-align: center;
  color: #fff;
  font-family: 'Roboto', sans-serif;
}

body{
  margin: 0;
  padding-top: 20px;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2{
  font-family: 'Archivo Black', sans-serif;
}

h3, h4, h5, h3{
  font-family: 'Barlow Condensed', sans-serif;
}

.container{
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.light-row{
  background-image: url(./assets/lights-crop.gif);
  background-size: contain;
  background-repeat: repeat-x;
  height: 65px;
  width: 100%;
}

.tree-row{
  background-image: url(./assets/trees.gif);
  background-size: contain;
  background-repeat: repeat-x;
  height: 190px;
  width: 103%;
  left: -3%;
  position: fixed;
  bottom: 4%;
}

.branding{
  padding: 10px 25px;
}

.branding img.morrison-logo{
  width: 55%;
  max-width: 320px;
}

.branding h1{
  font-size: 20px;
  line-height: 1.33;
  margin: 15px 0 0;
}

.branding h1 img{
  display: inline-block;
  margin: 0 0 0 1px;
  position: relative;
  top: 8px;
  width: 20px;
}

.branding p{
  font-size: 13px;
  line-height: 1.5;
  margin: 20px 0 0;
}

.footer{
  position: relative;
  z-index: 99999;
  box-shadow: 0 0 30px 30px rgba(0, 0, 0, 0.6);
  background-color: rgba(0,0,0,.6);
}

.footer p{

  display: inline-block;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 14px;
  font-weight: 800;
  padding: 5px;

}

.footer p a{
  color: #fff;
}

#footer-logo{
  display: block;
  width: 150px;
  margin: 0 auto 50px;
}

#footer-logo img{
  width: 150px;
}

@media screen and (min-width: 480px) {
  .branding{
    max-width: 550px;
    margin: 0 auto;
  }

  .branding img.morrison-logo{
   margin-top: 15px; 
  }

  .branding h1{
   font-size: 26px;
   margin: 10px 0 0;
  }

  .branding h1 img{
    width: 26px;
    top: 10px;
  }

  .branding p{
    font-size: 14px;
  }

  .tree-row{
    top: 33%;
  }
}

@media screen and (min-width: 768px) {
  .tree-row{
    top: unset;
    height: 250px;
  }

  .light-row{
    height: 80px;
  }
}

@media screen and (min-width: 992px) {
  .branding{
    max-width: 700px;
  }

  .branding img.morrison-logo{
    
  }

  .branding h1{
    font-size: 32px;
    line-height: 42px;
  }

  .branding h1 img{
    width: 28px;
    top: 10px;
  }

  .branding p{
    font-size: 16px;
    margin-bottom: 20px;
  }
  
}

@media screen and (min-width: 1200px) {
  .branding{
    max-width: 768px;
    padding: 30px 0;
  }
  
}

@media screen and (min-width: 1400px) {
  .branding{
  }

  .branding img.morrison-logo{
    
  }

  .branding h1{
   
  }

  .branding h1 img{
   
  }

  .branding p{

  }
  
}

</style>
